import { ApolloError, useLazyQuery } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core';
import {
  LazyQueryHookOptions,
  LazyQueryResultTuple,
} from '@apollo/client/react/types/types';
import { DocumentNode } from 'graphql/language';
import { useRef, useState } from 'react';

const NUM_OF_TRIES = 3;
const DELAY_BETWEEN_RETRIES = 1000;

export interface OptionsType<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
> extends LazyQueryHookOptions<TData, TVariables> {
  numOfRetries?: number;
  delayBetweenRetries?: number;
}

const useLandaLazyQuery = <TData, TVariables extends OperationVariables>(
  query: DocumentNode,
  outerOptions: OptionsType<TData, TVariables>
): LazyQueryResultTuple<TData, TVariables> => {
  type GenericOptionsType = OptionsType<TData, TVariables>;

  const [loading, setLoading] = useState(false);
  const [queryFunction, queryResult] = useLazyQuery<TData, TVariables>(
    query,
    outerOptions
  );

  const retries = useRef(outerOptions?.numOfRetries || NUM_OF_TRIES);
  const delay = outerOptions?.delayBetweenRetries || DELAY_BETWEEN_RETRIES;

  const onCompleted = (options: GenericOptionsType) => (data: TData) => {
    setLoading(false);
    options?.onCompleted?.(data);
  };

  const onError = (options: GenericOptionsType) => (error: ApolloError) => {
    retries.current -= 1;
    if (retries.current <= 0) {
      setLoading(false);

      if (options.onError) {
        options.onError(error);
      }
      return;
    }
    setTimeout(() => {
      queryWrapper(options);
    }, delay);
  };

  const queryWrapper = (options?: GenericOptionsType) => {
    setLoading(true);
    const combinedOptions = { ...outerOptions, ...options };

    return queryFunction({
      ...combinedOptions,
      onError: onError(combinedOptions),
      onCompleted: onCompleted(combinedOptions),
    });
  };

  return [queryWrapper, { ...queryResult, loading }];
};

export default useLandaLazyQuery;
